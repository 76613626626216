var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100", attrs: { id: "top-navigation-component" } },
    [
      _vm.loaded
        ? _c(
            "b-navbar",
            {
              staticClass: "navbar-expand w-100",
              attrs: { id: "topNav", toggleable: "false", type: "dark" },
            },
            [
              _vm.validToken
                ? _c(
                    "b-navbar-nav",
                    {
                      staticClass: "nav-left ml-auto w-100",
                      class: { "push-left": _vm.displayIsMobile },
                      attrs: { type: "dark", variant: "dark" },
                    },
                    [
                      ["sm", "md"].includes(_vm.$mq)
                        ? _c(
                            "b-nav-item-dropdown",
                            { staticClass: "nav-left-dropdown" },
                            [
                              _c("template", { slot: "button-content" }, [
                                _c("div", { staticClass: "left-float" }, [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "nav-icon-label d-flex flex-row justify-content-md-start align-items-center",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "dropdown-label" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fa-stack" },
                                            [
                                              _c("fa-icon", {
                                                staticClass:
                                                  "fa-stack-2x fa-xs ml-0 mr-0 icon-positioning",
                                                attrs: { icon: "bars" },
                                              }),
                                              _vm.dashpanelItemCount &&
                                              !_vm.$route.path.includes(
                                                "dashpanel"
                                              )
                                                ? _c("fa-icon", {
                                                    staticClass:
                                                      "fa-stack-1x float-right circle mr-1 mt-1",
                                                    attrs: { icon: "circle" },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "pr-1 top-navigation-menu",
                                        },
                                        [_vm._v("Menu")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "b-dropdown-item",
                                { staticClass: "mb-2 hire-us-dropdown-item" },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "btn-shadow mobile-nav-btn-width",
                                      attrs: {
                                        "data-cy": "hireUsBtn",
                                        to: "/hire-us",
                                        variant: "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "float-left" },
                                        [
                                          _c(
                                            "i",
                                            [
                                              _c("feather-icon", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  type: "shopping-cart",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("span", { staticClass: "ml-2" }, [
                                            _vm._v("Hire Us"),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "nav-link link-btn",
                                  class: _vm.isActiveRoute("dashpanel"),
                                  attrs: { to: "/dashpanel" },
                                },
                                [
                                  _c("ct-menu-item", {
                                    attrs: {
                                      unread:
                                        _vm.dashpanelItemCount &&
                                        !_vm.$route.path.includes("dashpanel"),
                                      "icon-type": "activity",
                                      "display-text": "Home",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.hasStagelineAccess
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "nav-link link-btn",
                                      class:
                                        _vm.isActiveRoute("/") ||
                                        _vm.isActiveRoute("stageline") ||
                                        _vm.isActiveRoute("stageline-v2"),
                                      attrs: { to: "/" },
                                    },
                                    [
                                      _c("fa-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          icon: ["far", "dot-circle"],
                                          size: "lg",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          "Manage Your " +
                                            _vm._s(_vm.managerYourTitle)
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "nav-link link-btn",
                                  class: _vm.isActiveRoute("documents"),
                                  attrs: { to: { name: "documents" } },
                                },
                                [
                                  _c("ct-menu-item", {
                                    attrs: {
                                      unread:
                                        _vm.unreadDocuments &&
                                        !_vm.$route.path.includes("documents"),
                                      "icon-type": "file-text",
                                      "display-text": "Documents",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "nav-link link-btn",
                                  class: _vm.isActiveRoute("invoices"),
                                  attrs: { to: { name: "invoices" } },
                                },
                                [
                                  _c("ct-menu-item", {
                                    attrs: {
                                      unread:
                                        _vm.unpaidInvoices &&
                                        !_vm.$route.path.includes("invoices"),
                                      "icon-type": "credit-card",
                                      "display-text": "Invoices",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "nav-link link-btn",
                                  class: _vm.isActiveRoute("services"),
                                  attrs: { to: { name: "services" } },
                                },
                                [
                                  _c("ct-menu-item", {
                                    attrs: {
                                      unread:
                                        _vm.servicesRequiringAttentionCount &&
                                        !_vm.$route.path.includes("services"),
                                      "icon-type": "globe",
                                      "display-text": "Services",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.hasVirtualPhoneService
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "nav-link link-btn",
                                      class: _vm.isActiveRoute("phones"),
                                      attrs: { to: { name: "phones" } },
                                    },
                                    [
                                      _c("ct-menu-item", {
                                        attrs: {
                                          unread: _vm.phoneNotificationCount,
                                          "icon-type": "phone",
                                          "display-text": "Phones",
                                          "configuration-needed-count":
                                            _vm.virtualPhonesToConfigure.length,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "nav-link link-btn",
                                  class: _vm.isActiveRoute("registrations"),
                                  attrs: { to: { name: "registrations" } },
                                },
                                [
                                  _c("ct-menu-item", {
                                    attrs: {
                                      "icon-type": "shield",
                                      "display-text": "Registrations",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "nav-link link-btn",
                                  class: _vm.isActiveRoute("pending-filings"),
                                  attrs: { to: { name: "pendingFilings" } },
                                },
                                [
                                  _c("ct-menu-item", {
                                    attrs: {
                                      unread:
                                        _vm.pendingFilings &&
                                        !_vm.$route.path.includes(
                                          "pending-filings"
                                        ),
                                      "icon-type": "clipboard",
                                      "display-text": "Filing Orders",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "nav-link link-btn",
                                  class: _vm.isActiveRoute("make-a-filing"),
                                  attrs: { to: { name: "companySelection" } },
                                },
                                [
                                  _c("ct-menu-item", {
                                    attrs: {
                                      "icon-type": "clipboard",
                                      "display-text": "Forms Library",
                                    },
                                  }),
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "nav-link link-btn",
                                      class: _vm.isActiveRoute("drafts"),
                                      attrs: { to: { name: "savedDrafts" } },
                                    },
                                    [
                                      _c("ct-menu-item", {
                                        attrs: {
                                          "icon-type": "save",
                                          "display-text": "Saved Progress",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isMontanaAcct
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "nav-link link-btn",
                                      class: _vm.isActiveRoute(
                                        "vehicle-registrations"
                                      ),
                                      attrs: { to: "/vehicle-registrations" },
                                    },
                                    [
                                      _c("fa-icon", {
                                        staticClass: "mr-2 icon-car-alt",
                                        attrs: { icon: "car-alt" },
                                      }),
                                      _c("span", [
                                        _vm._v("Vehicle Registrations"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.hasActiveMonitoringService
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "nav-link link-btn",
                                      class: _vm.isActiveRoute(
                                        "monitoring-overview"
                                      ),
                                      attrs: {
                                        to: { name: "monitoring-overview" },
                                      },
                                    },
                                    [
                                      _c("fa-icon", {
                                        staticClass: "mr-2 icon-car-alt",
                                        attrs: { icon: "trademark" },
                                      }),
                                      _c("span", [_vm._v("Monitoring")]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "nav-link link-btn",
                                  class: _vm.isActiveRoute("websites"),
                                  attrs: { to: _vm.websiteNavigation },
                                },
                                [
                                  _c("ct-menu-item", {
                                    attrs: {
                                      "icon-type": "globe",
                                      "display-text": "Websites & Email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : [
                            _c(
                              "ct-top-nav-item",
                              {
                                staticClass: "navbar-brand",
                                class: _vm.isActiveRoute("dashpanel"),
                                attrs: { to: { name: "dashpanel" } },
                              },
                              [
                                _c("template", { slot: "display-html" }, [
                                  _vm._v("\n            Home\n          "),
                                ]),
                              ],
                              2
                            ),
                            _vm.hasStagelineAccess
                              ? _c(
                                  "ct-top-nav-item",
                                  {
                                    class:
                                      _vm.isActiveRoute("/") ||
                                      _vm.isActiveRoute("stageline") ||
                                      _vm.isActiveRoute("stageline-v2"),
                                    attrs: { to: "/" },
                                  },
                                  [
                                    _c("template", { slot: "display-html" }, [
                                      _vm._v("\n            Manage"),
                                      _c("br"),
                                      _vm._v(
                                        "Your " +
                                          _vm._s(_vm.managerYourTitle) +
                                          "\n          "
                                      ),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "ct-top-nav-item",
                              {
                                class: _vm.isActiveRoute("make-a-filing"),
                                attrs: { to: { name: "companySelection" } },
                              },
                              [
                                _c("template", { slot: "display-html" }, [
                                  _vm._v("\n            Forms"),
                                  _c("br"),
                                  _vm._v("Library\n          "),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "ct-top-nav-item",
                              {
                                class: _vm.isActiveRoute("registrations"),
                                attrs: { to: { name: "registrations" } },
                              },
                              [
                                _c("template", { slot: "display-html" }, [
                                  _vm._v(
                                    "\n            Registrations\n          "
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "ct-top-nav-item",
                              {
                                class: _vm.isActiveRoute("documents"),
                                attrs: {
                                  to: { name: "documents" },
                                  "unread-count": _vm.unreadDocuments,
                                },
                              },
                              [
                                _c("template", { slot: "display-html" }, [
                                  _vm._v("\n            Documents\n          "),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "ct-top-nav-item",
                              {
                                class: _vm.isActiveRoute("invoices"),
                                attrs: {
                                  to: { name: "invoices" },
                                  "unread-count": _vm.unpaidInvoices,
                                },
                              },
                              [
                                _c("template", { slot: "display-html" }, [
                                  _vm._v("\n            Invoices\n          "),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "ct-top-nav-item",
                              {
                                class: _vm.isActiveRoute("services"),
                                attrs: { to: { name: "services" } },
                              },
                              [
                                _c("template", { slot: "display-html" }, [
                                  _vm._v("\n            Services\n          "),
                                ]),
                              ],
                              2
                            ),
                            _vm.isMontanaAcct
                              ? _c(
                                  "ct-top-nav-item",
                                  {
                                    class: _vm.isActiveRoute(
                                      "vehicle-registrations"
                                    ),
                                    attrs: { to: "/vehicle-registrations" },
                                  },
                                  [
                                    _c("template", { slot: "display-html" }, [
                                      _vm._v("\n            Vehicle"),
                                      _c("br"),
                                      _vm._v("Registrations\n          "),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "ct-top-nav-item",
                              {
                                class: _vm.isActiveRoute("hire-us"),
                                attrs: { to: "/hire-us" },
                              },
                              [
                                _c("template", { slot: "display-html" }, [
                                  _vm._v("\n            Hire Us\n          "),
                                ]),
                              ],
                              2
                            ),
                            _vm.hasVirtualPhoneService
                              ? _c(
                                  "ct-top-nav-item",
                                  {
                                    class: _vm.isActiveRoute("phones"),
                                    attrs: {
                                      to: { name: "phones" },
                                      "unread-count":
                                        _vm.phoneNotificationCount,
                                      "configuration-needed-count":
                                        _vm.virtualPhonesToConfigure.length,
                                    },
                                  },
                                  [
                                    _c("template", { slot: "display-html" }, [
                                      _vm._v(
                                        "\n            Phones\n          "
                                      ),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.hasActiveMonitoringService
                              ? _c(
                                  "ct-top-nav-item",
                                  {
                                    class: _vm.isActiveRoute("monitoring"),
                                    attrs: {
                                      to: { name: "monitoring-overview" },
                                    },
                                  },
                                  [
                                    _c("template", { slot: "display-html" }, [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click:
                                              _vm.logMonitoringDashboardClick,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Monitoring\n            "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "ct-top-nav-item",
                              {
                                class: _vm.isActiveRoute("websites"),
                                attrs: { to: _vm.websiteNavigation },
                              },
                              [
                                _c("template", { slot: "display-html" }, [
                                  _vm._v(
                                    "\n            Websites & Email\n          "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { attrs: { id: "top-nav-right" } },
                [
                  _vm.validToken && _vm.showLawOnCallDropdown
                    ? _c("law-on-call-dropdown")
                    : _vm._e(),
                  _c("nav-cart-dropdown", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showCart(),
                        expression: "showCart()",
                      },
                    ],
                    attrs: { id: "nav-cart-dropdown-container" },
                  }),
                  _vm.validToken
                    ? _c("nav-feed-dropdown", {
                        attrs: { id: "nav-feed-dropdown-container" },
                      })
                    : _vm._e(),
                  _vm.validToken
                    ? _c(
                        "b-navbar-nav",
                        { attrs: { type: "dark", variant: "dark" } },
                        [
                          _vm.validToken
                            ? _c(
                                "b-nav-item-dropdown",
                                {
                                  staticClass: "dropdown-positioning",
                                  attrs: { right: "" },
                                },
                                [
                                  _c("template", { slot: "button-content" }, [
                                    _c("div", { staticClass: "left-float" }, [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "nav-icon-label d-flex flex-row align-items-center",
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: { type: "user" },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown-label top-nav-greeting",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column align-items-start",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "greeting" },
                                                    [
                                                      _vm._v(
                                                        "Hello" +
                                                          _vm._s(_vm.firstName)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "label" },
                                                    [_vm._v("Account Settings")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "nav-account-settings-alert-icon",
                                                { staticClass: "ml-2 m-1" }
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.notificationsCnt > 0
                                            ? _c(
                                                "b-badge",
                                                {
                                                  style: {
                                                    right:
                                                      _vm.$mq === "sm"
                                                        ? "20%"
                                                        : "40%",
                                                  },
                                                  attrs: {
                                                    pill: "",
                                                    variant: "danger",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.notificationsCnt
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "b-dropdown-text",
                                    {
                                      staticClass: "mt-2",
                                      class: {
                                        "expired-msg-width":
                                          _vm.hasExpiredCards ||
                                          _vm.hasExpiredSoonCards,
                                      },
                                    },
                                    [
                                      _c("div", { attrs: { id: "account" } }, [
                                        _vm._v(
                                          "\n              Welcome,\n            "
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { attrs: { id: "account-email" } },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.currentAccountEmail) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _c("expired-cards-message", {
                                        staticClass: "mt-3 mb-3",
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("hr"),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: "/account/account-settings",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Contact Details\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    { attrs: { to: "/account/contact-list" } },
                                    [_vm._v("\n            People\n          ")]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    { attrs: { to: "/account/invitations" } },
                                    [
                                      _vm._v(
                                        "\n            Invitations\n            "
                                      ),
                                      _vm.notificationsCnt > 0
                                        ? _c(
                                            "b-badge",
                                            {
                                              style: {
                                                right:
                                                  _vm.$mq === "sm"
                                                    ? "20%"
                                                    : "40%",
                                              },
                                              attrs: {
                                                pill: "",
                                                variant: "danger",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(_vm.notificationsCnt) +
                                                  "\n            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { to: "/account/shared-settings" },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Shared Companies\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { to: "/account/payment-methods" },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Payment Methods\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { to: "/account/change-password" },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Change Password\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: "/account/signature",
                                        "data-cy": "logout-btn",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Signatures\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        to: {
                                          name: "mobileAccess",
                                          params: {
                                            isModal: false,
                                            mobileAppType: "corporate-tools",
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Corporate Tools App\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.logFaqPageNavigation({
                                            type: "nav-link",
                                            action: "visit",
                                            timestamp: Date.now(),
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v("\n            FAQ\n          ")]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    { on: { click: _vm.signOut } },
                                    [
                                      _vm._v(
                                        "\n            Sign Out\n          "
                                      ),
                                    ]
                                  ),
                                  _c("hr"),
                                  _c("b-dropdown-text", [
                                    _c(
                                      "div",
                                      { staticClass: "have-questions" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mb-4 small-centered-text",
                                          },
                                          [
                                            _vm._v(
                                              "\n                Have questions?"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                We're here to help:\n              "
                                            ),
                                          ]
                                        ),
                                        _c("address", { staticClass: "mt-2" }, [
                                          _vm.websiteName
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-2 website-name",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(_vm.websiteName) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.address
                                            ? _c("p", { staticClass: "mb-0" }, [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(_vm.address.line1) +
                                                    " " +
                                                    _vm._s(_vm.address.line2) +
                                                    "\n                "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.address
                                            ? _c("p", { staticClass: "mb-3" }, [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(_vm.address.city) +
                                                    ", " +
                                                    _vm._s(_vm.address.state) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.address.zip_code
                                                    ) +
                                                    "\n                "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.phone
                                            ? _c(
                                                "p",
                                                { staticClass: "mb-2" },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: { type: "phone" },
                                                  }),
                                                  _c("abbr", {
                                                    directives: [
                                                      {
                                                        name: "phone-number",
                                                        rawName:
                                                          "v-phone-number",
                                                        value: _vm.phone,
                                                        expression: "phone",
                                                      },
                                                    ],
                                                    attrs: {
                                                      title: "Telephone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.websiteEmails
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "email-ellipse mt-0",
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: { type: "mail" },
                                                  }),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: `mailto:${_vm.websiteEmails[0]}`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.websiteEmails[0]
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.loaded && _vm.shouldShowPhoneServiceBanner
        ? _c("div", { staticClass: "phone-service-banner" }, [_vm._m(0)])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "message" }, [
      _c("span", { staticClass: "message-content" }, [
        _c("b", [
          _vm._v(
            "Starting September 30th messaging and calling will be limited to mobile app only, and deprecated in the web portal. "
          ),
          _c(
            "a",
            {
              staticClass: "link-opacity-100 banner-link",
              attrs: { href: "#/account/mobile-access" },
            },
            [_vm._v("Download the mobile app.")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }