<template>
  <div id="top-navigation-component" class="w-100">
    <b-navbar
      v-if="loaded"
      id="topNav"
      toggleable="false"
      type="dark"
      class="navbar-expand w-100"
    >
      <b-navbar-nav
        v-if="validToken"
        class="nav-left ml-auto w-100"
        :class="{ 'push-left': displayIsMobile }"
        type="dark"
        variant="dark"
      >
        <b-nav-item-dropdown
          v-if="['sm','md'].includes($mq)"
          class="nav-left-dropdown"
        >
          <template slot="button-content">
            <div class="left-float">
              <i class="nav-icon-label d-flex flex-row justify-content-md-start align-items-center">
                <span class="dropdown-label">
                  <span class="fa-stack">
                    <fa-icon icon="bars" class="fa-stack-2x fa-xs ml-0 mr-0 icon-positioning" />
                    <fa-icon v-if="dashpanelItemCount && !$route.path.includes('dashpanel')" class="fa-stack-1x float-right circle mr-1 mt-1" icon="circle" />
                  </span>
                </span>
                <span class="pr-1 top-navigation-menu">Menu</span>
              </i>
            </div>
          </template>

          <b-dropdown-item class="mb-2 hire-us-dropdown-item">
            <b-btn data-cy="hireUsBtn" to="/hire-us" variant="primary" class="btn-shadow mobile-nav-btn-width">
              <span class="float-left">
                <i>
                  <feather-icon type="shopping-cart" class="pl-0" />
                </i>
                <span class="ml-2">Hire Us</span>
              </span>
            </b-btn>
          </b-dropdown-item>

          <b-dropdown-item
            :to="'/dashpanel'"
            class="nav-link link-btn"
            :class="isActiveRoute('dashpanel')"
          >
            <ct-menu-item
              :unread="dashpanelItemCount && !$route.path.includes('dashpanel')"
              icon-type="activity"
              display-text="Home"
            />
          </b-dropdown-item>

          <b-dropdown-item
            v-if="hasStagelineAccess"
            :to="'/'"
            class="nav-link link-btn"
            :class="isActiveRoute('/') || isActiveRoute('stageline') || isActiveRoute('stageline-v2')"
          >
            <fa-icon :icon="['far', 'dot-circle']" class="mr-2" size="lg" />
            <span>Manage Your {{ managerYourTitle }}</span>
          </b-dropdown-item>

          <b-dropdown-item
            :to="{name: 'documents'}"
            class="nav-link link-btn"
            :class="isActiveRoute('documents')"
          >
            <ct-menu-item
              :unread="unreadDocuments && !$route.path.includes('documents')"
              icon-type="file-text"
              display-text="Documents"
            />
          </b-dropdown-item>

          <b-dropdown-item
            :to="{name: 'invoices'}"
            class="nav-link link-btn"
            :class="isActiveRoute('invoices')"
          >
            <ct-menu-item
              :unread="unpaidInvoices && !$route.path.includes('invoices')"
              icon-type="credit-card"
              display-text="Invoices"
            />
          </b-dropdown-item>

          <b-dropdown-item
            :to="{name: 'services'}"
            class="nav-link link-btn"
            :class="isActiveRoute('services')"
          >
            <ct-menu-item
              :unread="servicesRequiringAttentionCount && !$route.path.includes('services')"
              icon-type="globe"
              display-text="Services"
            />
          </b-dropdown-item>

          <b-dropdown-item
            v-if="hasVirtualPhoneService"
            :to="{name: 'phones'}"
            class="nav-link link-btn"
            :class="isActiveRoute('phones')"
          >
            <ct-menu-item
              :unread="phoneNotificationCount"
              icon-type="phone"
              display-text="Phones"
              :configuration-needed-count="virtualPhonesToConfigure.length"
            />
          </b-dropdown-item>

          <b-dropdown-item
            :to="{name: 'registrations'}"
            class="nav-link link-btn"
            :class="isActiveRoute('registrations')"
          >
            <ct-menu-item
              icon-type="shield"
              display-text="Registrations"
            />
          </b-dropdown-item>

          <b-dropdown-item
            :to="{name: 'pendingFilings'}"
            class="nav-link link-btn"
            :class="isActiveRoute('pending-filings')"
          >
            <ct-menu-item
              :unread="pendingFilings && !$route.path.includes('pending-filings')"
              icon-type="clipboard"
              display-text="Filing Orders"
            />
          </b-dropdown-item>

          <b-dropdown-item
            :to="{name: 'companySelection'}"
            class="nav-link link-btn"
            :class="isActiveRoute('make-a-filing')"
          >
            <ct-menu-item
              icon-type="clipboard"
              display-text="Forms Library"
            />
          </b-dropdown-item>

          <b-dropdown-item
            v-if="false"
            :to="{name: 'savedDrafts'}"
            class="nav-link link-btn"
            :class="isActiveRoute('drafts')"
          >
            <ct-menu-item
              icon-type="save"
              display-text="Saved Progress"
            />
          </b-dropdown-item>

          <b-dropdown-item
            v-if="isMontanaAcct"
            to="/vehicle-registrations"
            class="nav-link link-btn"
            :class="isActiveRoute('vehicle-registrations')"
          >
            <fa-icon icon="car-alt" class="mr-2 icon-car-alt" />
            <span>Vehicle Registrations</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="hasActiveMonitoringService"
            :to="{name: 'monitoring-overview'}"
            class="nav-link link-btn"
            :class="isActiveRoute('monitoring-overview')"
          >
            <fa-icon icon="trademark" class="mr-2 icon-car-alt" />
            <span>Monitoring</span>
          </b-dropdown-item>

          <b-dropdown-item
            :to="websiteNavigation"
            class="nav-link link-btn"
            :class="isActiveRoute('websites')"
          >
            <ct-menu-item
              icon-type="globe"
              display-text="Websites & Email"
            />
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <template v-else>
          <ct-top-nav-item
            :to="{ name: 'dashpanel' }"
            class="navbar-brand"
            :class="isActiveRoute('dashpanel')"
          >
            <template slot="display-html">
              Home
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            v-if="hasStagelineAccess"
            :to="'/'"
            :class="isActiveRoute('/') || isActiveRoute('stageline') || isActiveRoute('stageline-v2')"
          >
            <template slot="display-html">
              Manage<br>Your {{ managerYourTitle }}
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            :to="{ name: 'companySelection' }"
            :class="isActiveRoute('make-a-filing')"
          >
            <template slot="display-html">
              Forms<br>Library
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            :to="{ name: 'registrations' }"
            :class="isActiveRoute('registrations')"
          >
            <template slot="display-html">
              Registrations
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            :to="{ name: 'documents' }"
            :class="isActiveRoute('documents')"
            :unread-count="unreadDocuments"
          >
            <template slot="display-html">
              Documents
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            :to="{ name: 'invoices' }"
            :class="isActiveRoute('invoices')"
            :unread-count="unpaidInvoices"
          >
            <template slot="display-html">
              Invoices
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            :to="{ name: 'services' }"
            :class="isActiveRoute('services')"
          >
            <template slot="display-html">
              Services
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            v-if="isMontanaAcct"
            to="/vehicle-registrations"
            :class="isActiveRoute('vehicle-registrations')"
          >
            <template slot="display-html">
              Vehicle<br>Registrations
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            to="/hire-us"
            :class="isActiveRoute('hire-us')"
          >
            <template slot="display-html">
              Hire Us
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            v-if="hasVirtualPhoneService"
            :to="{name: 'phones'}"
            :class="isActiveRoute('phones')"
            :unread-count="phoneNotificationCount"
            :configuration-needed-count="virtualPhonesToConfigure.length"
          >
            <template slot="display-html">
              Phones
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            v-if="hasActiveMonitoringService"
            :to="{name: 'monitoring-overview'}"
            :class="isActiveRoute('monitoring')"
          >
            <template slot="display-html">
              <div v-on:click="logMonitoringDashboardClick">
                Monitoring
              </div>
            </template>
          </ct-top-nav-item>
          <ct-top-nav-item
            :to="websiteNavigation"
            :class="isActiveRoute('websites')"
          >
            <template slot="display-html">
              Websites & Email
            </template>
          </ct-top-nav-item>
        </template>
      </b-navbar-nav>

      <div id="top-nav-right">
        <law-on-call-dropdown v-if="validToken && showLawOnCallDropdown" />
        <nav-cart-dropdown
          v-show="showCart()"
          id="nav-cart-dropdown-container"
        />
        <nav-feed-dropdown
          v-if="validToken"
          id="nav-feed-dropdown-container"
        />
        <b-navbar-nav v-if="validToken" type="dark" variant="dark">
          <b-nav-item-dropdown v-if="validToken" right class="dropdown-positioning">
            <template slot="button-content">
              <div class="left-float">
                <i class="nav-icon-label d-flex flex-row align-items-center">
                  <feather-icon type="user" />
                  <span class="dropdown-label top-nav-greeting">
                    <span class="d-flex flex-column align-items-start">
                      <span class="greeting">Hello{{ firstName }}</span>
                      <span class="label">Account Settings</span>
                    </span>
                    <nav-account-settings-alert-icon class="ml-2 m-1" />
                  </span>
                  <b-badge v-if="notificationsCnt > 0" pill variant="danger" :style="{ right: $mq === 'sm' ? '20%' : '40%' }">
                    {{ notificationsCnt }}
                  </b-badge>
                </i>
              </div>
            </template>

            <b-dropdown-text class="mt-2" :class="{ 'expired-msg-width': hasExpiredCards || hasExpiredSoonCards }">
              <div id="account">
                Welcome,
              </div>
              <div id="account-email">
                {{ currentAccountEmail }}
              </div>
              <expired-cards-message class="mt-3 mb-3" />
            </b-dropdown-text>

            <hr>

            <b-dropdown-item to="/account/account-settings">
              Contact Details
            </b-dropdown-item>
            <b-dropdown-item to="/account/contact-list">
              People
            </b-dropdown-item>
            <!-- TODO hide company details dropdown until they launch in the client portal, this is -->
            <!--   just for testing purposes to make sure it still functions correctly in both contexts -->
            <!-- <b-dropdown-item to="/company-details">-->
            <!--   Company Details-->
            <!-- </b-dropdown-item>-->
            <b-dropdown-item to="/account/invitations">
              Invitations
              <b-badge v-if="notificationsCnt>0" pill variant="danger" :style="{ right: $mq === 'sm' ? '20%' : '40%' }">
                {{ notificationsCnt }}
              </b-badge>
            </b-dropdown-item>
            <b-dropdown-item to="/account/shared-settings">
              Shared Companies
            </b-dropdown-item>
            <b-dropdown-item to="/account/payment-methods">
              Payment Methods
            </b-dropdown-item>
            <b-dropdown-item to="/account/change-password">
              Change Password
            </b-dropdown-item>
            <b-dropdown-item to="/account/signature" data-cy="logout-btn">
              Signatures
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'mobileAccess', params: { isModal: false, mobileAppType: 'corporate-tools' }}">
              Corporate Tools App
            </b-dropdown-item>
            <b-dropdown-item @click="logFaqPageNavigation({ type: 'nav-link', action: 'visit', timestamp: Date.now() })">
              FAQ
            </b-dropdown-item>
            <b-dropdown-item @click="signOut">
              Sign Out
            </b-dropdown-item>

            <hr>

            <b-dropdown-text>
              <div class="have-questions">
                <p class="mb-4 small-centered-text">
                  Have questions?<br>
                  We're here to help:
                </p>

                <address class="mt-2">
                  <p v-if="websiteName" class="mb-2 website-name">
                    {{ websiteName }}
                  </p>
                  <p v-if="address" class="mb-0">
                    {{ address.line1 }} {{ address.line2 }}
                  </p>
                  <p v-if="address" class="mb-3">
                    {{ address.city }}, {{ address.state }} {{ address.zip_code }}
                  </p>
                  <p v-if="phone" class="mb-2">
                    <feather-icon type="phone" />
                    <abbr v-phone-number="phone" title="Telephone" />
                  </p>
                  <p v-if="websiteEmails" class="email-ellipse mt-0">
                    <feather-icon type="mail" />
                    <a :href="`mailto:${ websiteEmails[0] }`">
                      {{ websiteEmails[0] }}
                    </a>
                  </p>
                </address>
              </div>
            </b-dropdown-text>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </b-navbar>
    <div v-if="loaded && shouldShowPhoneServiceBanner" class="phone-service-banner">
      <div class="message">
        <span class="message-content">
          <b>Starting September 30th messaging and calling will be limited to mobile app only, and deprecated in the web portal. <a href="#/account/mobile-access" class="link-opacity-100 banner-link">Download the mobile app.</a></b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { RESET } from '@/store/actions'
import { phoneNumber } from '@/directives/directives'
import * as Cookies from 'js-cookie'
import FeatherIcon from '@/components/shared/FeatherIcon'
import NavCartDropdown from './NavCartDropdown'
import CtMenuItem from '@/components/shared/CtMenuItem'
import CtTopNavItem from '@/components/shared/CtTopNavItem'
import LawOnCallDropdown from "@/components/navs/LawOnCallDropdown"
import NavFeedDropdown from '@/components/navs/NavFeedDropdown'
import NavAccountSettingsAlertIcon from '@/components/navs/NavAccountSettingsAlertIcon'
import ExpiredCardsMessage from '@/components/Payments/ExpiredCards/ExpiredCardsMessage'
import { faqMixin } from '@/mixins/faqMixin'
import store from '@/store'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'TopNavigation',
  components: {
    ExpiredCardsMessage,
    NavAccountSettingsAlertIcon,
    NavFeedDropdown,
    LawOnCallDropdown,
    FeatherIcon,
    NavCartDropdown,
    CtMenuItem,
    CtTopNavItem,
  },
  directives: {
    phoneNumber,
  },
  mixins: [faqMixin],
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('session', {
      token: 'getToken',
      accountEmail: 'getAccountEmail',
      websiteId: 'getWebsiteId',
    }),
    ...mapGetters('account', [
      'account',
      'isRegularAccount',
    ]),
    ...mapGetters('dashpanel', [
      'unreadDocuments',
      'unpaidInvoices',
      'servicesRequiringAttentionCount',
      'pendingFilings',
      'isMontana',
      'dashpanelItemCount',
      'isSubscriptionAccount',
      'lawOnCallOffered',
      'activeLawOnCallServices',
      'activeDomains',
      'isEntityTypeDomains',
    ]),
    ...mapGetters('stageline', [
      'isStagelineRoute',
      'stagelineConfig',
    ]),
    ...mapGetters('virtualPhones', [
      'virtualPhones',
      'virtualPhonesFinished',
      'suspendedPhonesFinished',
      'virtualPhonesPendingRemoval',
      'phoneNotificationCount',
      'suspendedPhones',
      'virtualPhonesToConfigure',
    ]),
    ...mapGetters('monitoring', [
      'monitoringServiceActive',
    ]),
    ...mapGetters('paymentMethods', ['hasExpiredCards', 'hasExpiredSoonCards']),
    ...mapGetters('vouchers', [
      'companyIdFromBusinessDomainVouchers',
    ]),
    hasPhonePendingRemoval() {
      return this.virtualPhonesPendingRemoval?.length > 0
    },
    hasActivePhone() {
      return this.virtualPhones?.length > 0
    },
    hasSuspendedPhone() {
      return this.suspendedPhones?.length > 0
    },
    hasVirtualPhoneService() {
      return this.hasActivePhone || this.hasPhonePendingRemoval || this.hasSuspendedPhone
    },
    hasActiveMonitoringService() {
      return this.monitoringServiceActive
    },
    hasActiveDomains() {
      return this.activeDomains && this.activeDomains.length > 0
    },
    showLawOnCallDropdown() {
      return this.lawOnCallOffered || (this.activeLawOnCallServices?.length > 0)
    },
    firstName() {
      return this.account ? `, ${ this.account.first_name }` : ''
    },
    isMontanaAcct() {
      if (typeof this.isMontana === 'object') {
        return false
      } else {
        return this.isMontana
      }
    },
    websiteName: function() {
      return this.website && this.website.name ? this.website.name : null
    },
    websiteEmails: function() {
      return this.website && this.website.emails ? this.website.emails : null
    },
    address() {
      if (!this.website || !Array.isArray(this.website.addresses)) {
        return null
      }
      return this.website.addresses.find((a) => a.kind === 'primary' || a.kind === 'default') || null
    },
    phone() {
      if (!this.website || !Array.isArray(this.website.phone_numbers)) {
        return null
      }

      return (this.website.phone_numbers.find((a) => a.kind === 'billing') || {}).phone_number
    },
    validToken() {
      return this.token && this.website
    },
    currentAccountEmail: function() {
      return this.accountEmail ? this.accountEmail : ''
    },
    notificationsCnt() {
      // Add any additional notification getters to this if needed
      return this.$store.getters['invitations/invitationsReceivedCount']
    },
    displayIsMobile() {
      return this.$mq !== 'lg'
    },
    websiteNavigation() {
      const id = this.hasActiveDomains ? null : this.companyIdFromBusinessDomainVouchers
      return id ? { name: 'stageline-v2-stage', params: { companyId: id, stageIdOrName: 'configure_domain' } } : { name: 'websites' }
    },
    shouldShowPhoneServiceBanner() {
      if (this.hasVirtualPhoneService) {
        const phoneRoutes = ['phones', 'call', 'sms-chat', 'voicemails', 'settings', 'contacts']
        return phoneRoutes.includes(this.$route.name)
      } else {
        return false
      }
    },
    isAdmin() {
      return sessionStorage.getItem('admin-logged-in') === 'true'
    },
    managerYourTitle() {
      return this.isEntityTypeDomains ? 'Domains' : 'Business'
    },
    hasStagelineAccess() {
      return this.website?.stageline_enabled && this.isRegularAccount
    },
  },
  watch: {
    token(val) {
      if (!val) {
        global.capturePathAndClearState()
      }
    },
  },
  async created() {
    this.loaded = false
    this.website = this.$store.getters['website/website']

    await this.refreshDashpanel()

    this.loaded = true
  },
  async mounted() {
    await this.fetchAccountDomainVouchers()
  },
  methods: {
    ...mapActions('dashpanel', {
      refreshDashpanel: 'refresh',
    }),
    ...mapActions('verifyOrder', [
      'loadLatestOrderRequiringVerification',
    ]),
    ...mapActions('vouchers', [
      'fetchAccountDomainVouchers',
    ]),
    signOut() {
      // Remove any possible tokens
      [`.${window.location.host}`, window.location.hostname]
        .forEach(domain => this.removePossibleTokens(domain))
      this.$store.dispatch(RESET, {}, { root: true })

      if (store.state.session.keycloakAuthenticated) {
        let logoutOptions = { redirectUri: this.website ? this.website.login_url : window.location.origin }
        store.state.session.keycloak.logout(logoutOptions)
      } else {
        this.website ? location.replace(this.website.login_url) : this.$router.push("/login")
      }
    },
    showCart() {
      return this.$router.currentRoute.meta.showCart && !(this.isStagelineRoute && this.stagelineConfig?.hide_cart)
    },
    removePossibleTokens(domain) {
      Cookies.remove('token', { domain: domain })
    },
    async logMonitoringDashboardClick() {
      if (this.isAdmin)
        return

      await createOrFindClientInteractionLog({
        category: 'monitoring',
        subCategory: 'clicked-dashboard',
        interaction: {
          type: 'button',
          action: 'redirect',
          name: 'clicked-dashboard',
        },
        accountId: this.account.id,
      })
    },
    isActiveRoute(path) {
      const basePath = this.$route.path.split('/')[1] || '/'
      return basePath === path || (path === '/' && this.$route.path === '/') ? 'active' : ''
    },
  },
}
</script>

<style lang="scss">
#top-navigation-component {

  #topNav {
    min-height: 60px;
    max-height: 60px;
    flex: 1;
    background: $ct-top-nav-color;
  }

  * ::after {
    position: relative;
    top: 5px;
  }

  .nav-left {
    height: 80%;

    .nav-left-dropdown {
      display: flex;
      align-items: center;

      .mobile-nav-btn-width {
        width: 10rem;
      }

      .dropdown-toggle {
        padding: 0;
      }

      .nav-link.link-btn {
        padding: 0;
        color: $ct-ui-color-17;
      }

      .dropdown-item {
        height: 46px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      .active:not(.hire-us-dropdown-item .dropdown-item) {
        background-color: $ct-ui-color-1;
      }

      .hire-us-dropdown-item .dropdown-item:hover {
        background-color: initial;
      }
    }
  }

  .push-left {
    position: relative;
    right: 24px;
  }

  .dropdown-label {
    margin: 0 10px 0 10px;
    display: flex;
    align-items: flex-end;
  }

  .greeting {
    line-height: 15px;
    font-size: .9rem;
    opacity: .7;
  }
  .label {
    font-weight: 700;
    font-size: 1rem;
    opacity: 1;
  }

  #account {
    font-size: .8rem;
    opacity: .6;
  }

  #account-email {
    font-size: .9rem;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  #contact-form {
    margin-bottom: 2em;
  }

  .website-name {
    font-size: 115%;
    font-weight: 600;
  }

  .email-ellipse {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  hr {
    margin: 0.9375em 2.1875em 0.9375em 2.1875em;
  }

  address {
    font-size: .9rem;
  }

  #top-nav-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 80%;
    width: 450px;
  }

  #nav-cart-dropdown-container {
    margin-right: 30px;
  }

  .circle {
    color: #dc3545;
    right: -.5rem;
  }

  .icon-car-alt {
    width: 24px;
    height: 24px;
  }

  .expired-msg-width {
    width: 17.625rem;
  }

  @media only screen and (max-width: 660px) {
    h1 {
      font-size: 1.825rem !important;
    }

    #content-flex-container {
      min-height: 400px;
    }

    .mobileNavigation {
      position: fixed;
      bottom: 60px;
    }

    div.top-navigation-menu {
      display: none;
    }

    div.top-nav-greeting {
      display: none;
    }
  }
}

.phone-service-banner {
  background-color: #FFE0B2;
  color: #4E342E;
  padding: 24px 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .message {
    margin: auto;
  }
  .message-content {
    flex: 1;
  }
  .banner-link {
    color: #E65100;
    text-decoration: underline;
  }
  .banner-link:hover {
    text-decoration: none;
  }
}

.icon {
  margin-right: 3px;
  font-size: 20px;
  cursor: pointer;
}

.left-float {
  float: left;
}

.icon-positioning {
  position: relative;
  left: 12px;
  top: 4px;
}

.dropdown-positioning {
  position: relative;
  right: 10px;
  bottom: 2px;
}

.small-centered-text {
  text-align: center;
  font-size: .9em;
}

.have-questions {
  width: 230px;
}

</style>
